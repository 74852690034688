<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Choices from "choices.js";



import {getBankReconciledApi} from '@/api/accounting/reconcile'
import {getCashApi} from '@/api/cash'
import moment from 'moment'

/**
 * User list component
 */
export default {
  page: {
    title: "Reconciliation",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      stmt_list : [],
      list: [],
      account_list: [],
      acount_choice_obj: {},

      current_account: {
      },
      current_stmt : {
        open_balance : 0,
        statement_balance : 0,
      },

      book_amount : 0,
      open_amount :0,
      miscell_amount : 0,
      outgoing_amount : 0,
      incoming_amount: 0,
      store_list : [],
      reconcile_date : moment(moment().format("YYYY-MM-") + moment().daysInMonth()).format('YYYY-MM-DD'),
      list_type: 'RECONCILE_LIST',
      title: "Bank",
      items: [
        {
          text: "Reconciliation",
          href: "/",
        },
      ],
      totalRows: 0,
      totalMsRows :0,
      currentPage: 1,
      perPage: 1000,
      msPerPage: 1000,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "ID",
          sortable: true,
        },
        
        {
          key: "Date",
          sortable: true,
        },
        {
          key: "Trans",
          label : 'Ref',
          sortable: true,
        },
      
        {
          key: "Amount",
          sortable: true,
        },
        {
          key: "Description",
          sortable: false,
          label : 'Description'
        },
      ],

      ms_list : [],
      ms_fields: [
        {key : 'ID'},
        {key : 'update_date', label:'Date'},
        {key : 'Amount'},
        {key : 'remarks'}
      ]
    };
  },
  components: {
    Layout,
    PageHeader,
  
  },
  computed: {
   
    rows() {
      return this.totalRows
    },
    diff() {return this.current_stmt.open_balance + this.open_amount- this.miscell_amount- this.current_stmt.statement_balance}
  },

  created() {


    
    getCashApi().bank_account_list().then((res) =>{
      this.account_list.push({
          label: "",
          account: ""
        })
      if (res.errCode == 0) {
        res.data.accounts.map((a)=>{
          this.account_list.push({
            label       : a.name,
            value       : a.a_account,
            account     : a.a_account,
            account_obj : a,
            selected : true
          })
          this.current_account = a
        })
        this.acount_choice_obj.setChoices(this.account_list)
      }
    })
  },


  mounted() {

    this.acount_choice_obj= new Choices('#bank_account', {
      placeholderValue: "search account",
      searchPlaceholderValue: "search by name",
    })


    this.stmt_choice_obj= new Choices('#stmt_choice', {
      placeholderValue: "search Stmt",
      searchPlaceholderValue: "search by date",
    })
  
  },
  methods: {

   

    queryList() {
      
      let data = {
        "account" : this.current_account.a_account,
        "query_month" : this.current_stmt.fdate,
        'trans_code'  : this.current_stmt.finalized_code
      }
      this.list = []
      getBankReconciledApi().stmt_records_list(data).then((res)=>{
        if (res.errCode != 0) {
          return;
        }
        this.list = []
        //FIX me
        //this.open_amount = 0
        this.miscell_amount = 0
        this.outgoing_amount = 0
        this.incoming_amount = 0
        res.data.trans_list.map((o) =>{
        
          this.list.push({
            id: o.id,
            bank_name : o.bank_name,
            date :  moment(o.update_date).format("YYYY-MM-DD"),
            mdate :  moment(o.update_date),
            ref_id : o.ref_id,
            description: o.remarks,
            amount :o.amount, 
            flag : o.flag,
            op_flag : 0,
            origin_flag : o.flag,
          })
         
          if (o.flag == 1) {
            this.open_amount += o.amount
            if (o.amount > 0) {
              this.incoming_amount += o.amount
            } else {
              this.outgoing_amount += o.amount
            }
          }
        })

        
        this.totalRows = this.list.length;

        
        this.ms_list = []
        res.data.miscell_list.map((o) => {
          o.update_date = moment(o.update_date).format("YYYY-MM-DD")
          this.ms_list.push(o)
          this.miscell_amount += o.amount
        })
        this.totalMsRows = this.ms_list.length;
      })
    },


    changeAccount(evt) {
      let e  = this.account_list.find((e) => e.value == evt.detail.value)
      this.current_account =  e.account_obj


      getBankReconciledApi().reconcile_stmt_list({account : this.current_account.a_account}).then(res => {
        this.stmt_list = []
        this.stmt_list.push({
            label: "",
            value : '',
            account: "",
            stmt : {}
          })

        if (res.errCode == 0) {
          res.data.map((r) => {
            this.stmt_list.push({
              label : r.fdate,
              value : r.fdate,
              stmt : r
            })
          })
         
          this.stmt_choice_obj.clearChoices()
          this.stmt_choice_obj.setChoices(this.stmt_list)
        }
      })
    },

    changeStmtDate(evt) {
      let e  = this.stmt_list.find((e) => e.value == evt.detail.value)
      this.current_stmt = e.stmt
      this.open_amount  = this.current_stmt.last_opened_amount
      this.queryList()
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },



    list_sort() {
      this.list.sort((a, b) => {
        if (this.sortBy == 'Amount') {
          if (this.sortDesc) {
            return a.amount > b.amount ? -1 : 1
          } else {
            return a.amount > b.amount ? 1 : -1
          }
        } else if (this.sortBy == 'Date') {
          if (this.sortDesc) {
            return  -a.mdate.diff(b.mdate)
          } else {
            return a.mdate.diff(b.mdate)
          }
        } else if (this.sortBy == 'Trans') {
          if (this.sortDesc) {
            return a.trans_id > b.trans_id ? -1 : 1
          } else {
            return a.trans_id > b.trans_id ? 1 : -1
          }
        }
      })
    },

    get_description(item) {
      return item.description
    },

    get_flag_string(item) {
      if (item.flag == 1) {
        return 'Reconciled'
      } else if (item.flag == 2) {
        return 'Except'
      } else if (item.flag == 0) {
        return 'N/A'
      }
    },


    getStatment() {
      return '$'+this.current_stmt.statement_balance.toLocaleString()
    },

    getBankAccountBalance() {
      return (this.current_stmt.open_balance + this.open_amount- this.miscell_amount ).toLocaleString()
    },

    onReconcileDateChange() {
      this.queryList();
    },

    download_report() {
      let data = {
        account : this.current_account.a_account,
        finalized_date : this.current_stmt.finalized_date
      }
      getBankReconciledApi().get_reconciled_reports_file(data).then(res =>{
        if (res.errCode == 0) {
          window.open(res.data.url, '_blank');
        } else {
          this.$alertify.error("Failed to get reports " + res.errCode);
        }
      })
    },

    

    unfinalize() {
      let data = {
        reconciled_id : this.current_stmt.id
      }
      getBankReconciledApi().unfinalized_reconcile(data).then(res =>{
        if (res.errCode == 0) {
          this.$alertify.message("Unfinalized Successful");
          this.$router.go()
        } else {
          this.$alertify.error("Failed to unfinalize " + res.errCode);
        }
      })
    },

    export_records() {
      let data = {
        reconciled_id : this.current_stmt.id
      }
      getBankReconciledApi().export_reconciled_records(data).then(res =>{
        if (res.errCode == 0) {
          window.open(res.data.url, '_blank');
        } else {
          this.$alertify.error("Failed to get reports " + res.errCode);
        }
      })
    }
    
  },


  watch: {
    sortBy () {
      this.list_sort()
    },

    sortDesc () {
      this.list_sort()
    }
  }


};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">


            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <h5 class="card-title">
                    Reconciliation
                    <span class="text-muted fw-normal ms-2">({{ this.list_type=='RECONCILE_LIST'? this.totalRows: this.totalMsRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-8">           
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >

                <label class="mt-2">Reconcile Date:</label>
                <div class="col-md-3">
                  <select
                    class="form-control"
                    data-trigger
                    name="account"
                    id="stmt_choice"
                    @change="changeStmtDate"
                    >
                    <option value=" "></option>
                  </select>

                </div>

               
                <label class="mt-2 ml-3">Account:</label>
                <div class="search-box col-md-4">
                    <div class="position-relative">
                      <select
                          class="form-control"
                          data-trigger
                          name="account"
                          id="bank_account"
                          @change="changeAccount"
                          >
                          <option value=" "></option>
                        </select>
                      
                    </div>
                  </div>

                  
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->


            <div class="row">
              <div class="col-md-2">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>Book:${{ current_stmt.open_balance.toLocaleString() }}</label>
                </div>
              </div>

              <div class="col-md-1">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>+</label>
                </div>
              </div>

              <div class="col-md-2">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>Open: ${{ open_amount.toLocaleString() }}</label>
                </div>
              </div>
              <div class="col-md-1">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>-</label>
                </div>
              </div>

              <div class="col-md-2">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>Miscell: ${{ miscell_amount.toLocaleString() }}</label>
                </div>
              </div>
              <div class="col-md-1">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>=</label>
                </div>
              </div>


              <div class="col-md-3">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label>Bank Account: ${{ getBankAccountBalance()  }}</label>
                </div>
              </div>

            </div>
            <!-- end row -->
           

            <div class="row">
              <div class="col-md-3">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label >Outgoing Cleared:</label>
                </div>
              </div>
              <div class="col-md-1">
                <div class="d-flex flex-wrap   justify-content-end">
                  <label >{{ outgoing_amount.toLocaleString() }}</label>
                </div>
              </div>

              <div class="col-md-8">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                <label>   Statment: {{ getStatment() }}</label>
                </div>
              </div>

            </div>
            <!-- end row -->

            <div class="row">
              <div class="col-md-3">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label >Incoming Cleared:</label>
                </div>
              </div>
              <div class="col-md-1">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                  <label >{{ incoming_amount.toLocaleString() }}</label>
                </div>
              </div>

              <div class="col-md-8">
                <div class="d-flex flex-wrap align-items-start justify-content-end ">
                Diff:<label :class="diff != 0 ? 'text-danger' :''"> {{ '$'+diff.toLocaleString() }}</label>
                </div>
              </div>
            </div>
            <!-- end row -->

            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="d-flex flex-wrap align-items-start">
                    <b-button class="btn-soft-info" :pressed="this.list_type=='RECONCILE_LIST'?true: false"  variant="info" @click="list_type='RECONCILE_LIST' ">
                      <i class="uil uil-info-circle me-2"></i> Reconcile
                    </b-button>
                    <b-button class="btn-soft-danger" :pressed="this.list_type=='MISCELLANEOUS_LIST'?true: false"   variant="danger" @click="list_type='MISCELLANEOUS_LIST'" >
                      <i class="uil uil-exclamation-octagon me-2"></i> Miscellaneous
                    </b-button>
                  </div>
                </div>
              </div>

              <div class="col-md-2">
              </div>
              <!-- end col -->

              <div class="col-md-6">
                <div class="d-flex flex-wrap align-items-start justify-content-end gap-2">

                  
                  <b-button     variant="danger" @click="unfinalize()" >
                    <i class="uil uil-exclamation-octagon me-2"></i> UnFinalize
                  </b-button>

                  <b-button     variant="primary" @click="export_records()" >
                    <i class="uil uil-exclamation-octagon me-2"></i> Export Records
                  </b-button>

                  <b-button     variant="primary" @click="download_report()" >
                    <i class="uil uil-exclamation-octagon me-2"></i> Download Reports
                  </b-button>
                </div>
              </div>
               <!-- end col -->

            </div>
            <!-- end row -->

            

            <div class="table-responsive" v-if="this.list_type=='RECONCILE_LIST'" >
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="list"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  class="datatables"
                  style="min-height: 40dvh;"
                >

                  <template  #cell(ID)="data">
                    {{ data.index + 1 }}
                  </template>
                 
                  <template #cell(Account)="data"> 
                    {{ data.item.bank_name }}
                  </template>

                  <template #cell(Date)="data">             
                      {{ data.item.date }}
                  </template>
                  <template #cell(Trans)="data">
                    {{  data.item.ref_id}}
                  </template>

                  <template #cell(Ref)="data">
                    {{  data.item.ref_id}}
                  </template>
                 
                  
                  <template #cell(Amount)="data">
                    {{ '$'+data.item.amount.toLocaleString()}}
                  </template>

                  <template #cell(Description)="data">
                    {{  get_description(data.item)}}
                  </template>


                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <!-- Miscellaneous Record Table-->
            <div class="table-responsive" v-if="this.list_type=='MISCELLANEOUS_LIST'" >
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="ms_list"
                  :fields="ms_fields"
                  responsive="sm"
                  :per-page="msPerPage"
                  class="table-check"
                  style="min-height: 40dvh;"
                >

                  <template  #cell(ID)="data">
                    {{ data.index + 1 }}
                  </template>
                 

                  <template #cell(Date)="data">             
                      {{ data.item.date }}
                  </template>

                  
                  <template #cell(Amount)="data">
                    {{ '$'+data.item.amount.toLocaleString()}}
                  </template>



                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
